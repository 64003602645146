import * as types from '../constants'

const initialState = {}

export const dashboard = (state = initialState, actions) => {
  switch (actions.type) {
    case types.LOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...actions.payload,
      }
    default:
      return state
  }
}
