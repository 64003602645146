import { FaStar } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubstitutesReview } from '../../redux/actions/substituteReview'

const StarsWithRating = ({ substitute_id }) => {
  const [loading, setLoading] = useState(false)
  const [average, setAverage] = useState(0)
  const [total, setTotal] = useState(0)

  const dispatch = useDispatch()
  const expRatingStars = useSelector(
    (state) => state.substituteReview.substituteReviews
  )

  useEffect(() => {
    dispatch(getSubstitutesReview({ substitute_id }))
  }, [substitute_id, dispatch])

  useEffect(() => {
    setLoading(true)
    if (expRatingStars && expRatingStars.length > 0) {
      const averageCalc =
        expRatingStars.reduce((total, rating) => total + rating.score, 0) /
        expRatingStars.length

      setAverage(averageCalc)
      setTotal(expRatingStars.length)
      setLoading(false)
    } else {
    }
  }, [expRatingStars])

  return (
    <>
      {!loading && total > 0 && (
        <div className=" p-1 rounded">
          <strong className="text-secondary">{average.toFixed(1)}</strong>
          <FaStar size={20} className="text-secondary align-top ms-1" />
        </div>
      )}
    </>
  )
}

export default StarsWithRating
