import * as serviceWorker from './serviceWorker'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'

import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import { gtmInit } from './services/gtm-handler'

import 'react-widgets/dist/css/react-widgets.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-dates/lib/css/_datepicker.css' // default styles for react-dates, should be imported here, if not the component breaks
import '../src/assets/scss/3-components/_reactdate.scss' // custom styles for react-dates, overrides default styles
import 'react-dates/initialize'

gtmInit()

moment.locale('es')
moment.updateLocale('es', {
  week: {
    dow: 1,
  },
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
})
momentLocalizer()

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
