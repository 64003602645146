import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'
import { getRawDate } from '../../services'

export function createProcura(procura) {
  const token = localStorage.getItem('token')
  procura.raw_hearing_date = getRawDate(procura.hearing_date)
  procura.hearing_date = undefined

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(procura),
      endpoint: `${baseAPI}/procura`,
      method: 'POST',
      types: [
        types.CREATE_PROCURA_REQUEST,
        types.CREATE_PROCURA_SUCCESS,
        types.CREATE_PROCURA_ERROR,
      ],
    },
  }
}

export function clearProcura() {
  return {
    type: types.CLEAR_PROCURA,
    payload: {},
  }
}

export function loadProcuras({ query }) {
  const token = localStorage.getItem('token')

  const company = query.get('company')
  if (company) query.append('company_id', String(JSON.parse(company).value))

  const creator = query.get('creator')
  if (creator) query.append('creator_id', String(JSON.parse(creator).value))

  const substitute = query.get('substitute')
  if (substitute)
    query.append('substitute_id', String(JSON.parse(substitute).value))

  const partner = query.get('partner')
  if (partner) query.append('partner_id', String(JSON.parse(partner).value))

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura?${query.toString()}`,
      method: 'GET',
      types: [
        types.LOAD_PROCURAS_REQUEST,
        types.LOAD_PROCURAS_SUCCESS,
        types.LOAD_PROCURAS_ERROR,
      ],
    },
  }
}

export function loadProcura({ procura_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura/${procura_id}`,
      method: 'GET',
      types: [
        types.LOAD_PROCURA_REQUEST,
        types.LOAD_PROCURA_SUCCESS,
        types.LOAD_PROCURA_ERROR,
      ],
    },
  }
}

export function resetCreatedProcura() {
  return {
    type: types.RESET_CREATED_EXPEDIENT,
    payload: {},
  }
}

export function updateProcura({ procura_id, update }) {
  const token = localStorage.getItem('token')
  update.raw_hearing_date =
    update.hearing_date && getRawDate(update.hearing_date)

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ update }),
      endpoint: `${baseAPI}/procura/${procura_id}`,
      method: 'PUT',
      types: [
        types.UPDATE_PROCURA_REQUEST,
        types.UPDATE_PROCURA_SUCCESS,
        types.UPDATE_PROCURA_ERROR,
      ],
    },
  }
}

export function loadAssignedProcuras({ query }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procuras-assigned?${query.toString()}`,
      method: 'GET',
      types: [
        types.LOAD_ASSIGNED_PROCURA_REQUEST,
        types.LOAD_ASSIGNED_PROCURA_SUCCESS,
        types.LOAD_ASSIGNED_PROCURA_ERROR,
      ],
    },
  }
}

export function updateReport({ document_id, report, status }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ report, status }),
      endpoint: `${baseAPI}/procura/update-report/${document_id}`,
      method: 'POST',
      types: [
        types.UPDATE_PROCURA_REPORT_REQUEST,
        types.UPDATE_PROCURA_REPORT_SUCCESS,
        types.UPDATE_PROCURA_REPORT_ERROR,
      ],
    },
  }
}

export function loadDrafts({ query }) {
  const token = localStorage.getItem('token')
  query.append('status', 'draft')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura?${query.toString()}`,
      method: 'GET',
      types: [
        types.LOAD_PROCURA_DRAFTS_REQUEST,
        types.LOAD_PROCURA_DRAFTS_SUCCESS,
        types.LOAD_PROCURA_DRAFTS_ERROR,
      ],
    },
  }
}

export function deleteProcura({ procura_id, sendDeleteEmail }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ sendDeleteEmail }),
      endpoint: `${baseAPI}/procura/${procura_id}`,
      method: 'DELETE',
      types: [
        types.PROCURA_DELETED_REQUEST,
        types.PROCURA_DELETED_SUCCESS,
        types.PROCURA_DELETED_ERROR,
      ],
    },
  }
}

export function docClientReminder({ procura_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura/${procura_id}/docreminder`,
      method: 'POST',
      types: [
        types.PROCURA_DOC_REMINDER_REQUEST,
        types.PROCURA_DOC_REMINDER_SUCCESS,
        types.PROCURA_DOC_REMINDER_ERROR,
      ],
    },
  }
}

export function reassingProcura({ procura_id, substitute_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura/${procura_id}/reassign`,
      method: 'PUT',
      body: JSON.stringify({ substitute_id }),
      types: [
        types.REASSING_PROCURA_REQUEST,
        types.REASSING_PROCURA_SUCCESS,
        types.REASSING_PROCURA_ERROR,
      ],
    },
  }
}

export function expedientNotification({ data }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura-notifications`,
      method: 'POST',
      body: JSON.stringify({ data }),
      types: [
        types.PROCURA_NOTIFICATION_REQUEST,
        types.PROCURA_NOTIFICATION_SUCCESS,
        types.PROCURA_NOTIFICATION_ERROR,
      ],
    },
  }
}

export function deleteProcuraFile({ type, file, procura_id }) {
  const token = localStorage.getItem('token')
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura/${procura_id}/deleteFile`,
      method: 'PUT',
      body: JSON.stringify({ type, file }),
      types: [
        types.PROCURA_DELETE_FILE_REQUEST,
        types.PROCURA_DELETE_FILE_SUCCESS,
        types.PROCURA_DELETE_FILE_ERROR,
      ],
    },
  }
}

export function sendSubstituteUrgentIssueProcuraReminder({
  document_type,
  document_id,
  emails,
  admin_name,
}) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        document_type,
        document_id,
        emails,
        admin_name,
      }),
      endpoint: `${baseAPI}/urgent-issue-reminder/`,
      method: 'POST',
      types: [
        types.URGENT_ISSUE_REMINDER_PROCURA_REQUEST,
        types.URGENT_ISSUE_REMINDER_PROCURA_SUCCESS,
        types.URGENT_ISSUE_REMINDER_PROCURA_ERROR,
      ],
    },
  }
}
export function loadProcuraDrafts({ query }) {
  const token = localStorage.getItem('token')
  query.status = 'draft'

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/procura?${query.toString()}`,
      method: 'GET',
      types: [
        types.LOAD_PROCURA_DRAFTS_REQUEST,
        types.LOAD_PROCURA_DRAFTS_SUCCESS,
        types.LOAD_PROCURA_DRAFTS_ERROR,
      ],
    },
  }
}
