import React, { useState, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../redux/actions/users'
import { baseAPI } from '../config'
import * as Icon from 'react-feather'
import { IoMdClose } from 'react-icons/io'

const CheckoutModal = ({
  show,
  price = 95,
  closeCheckoutModal,
  createExpedient,
  cards,
  checkoutIntent,
  isUrgentHearing,
}) => {
  const [showSaveCards, setShowSaveCards] = useState(true)
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const { activeUser } = auth

  useEffect(() => {
    if (activeUser) {
      dispatch(updateUser({ user: activeUser, id: activeUser._id }))
    }
  }, [dispatch, activeUser])

  const paymentSaveCard = async (paymentMethodId) => {
    try {
      const response = await fetch(`${baseAPI}/charge-save-card`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: activeUser._id,
          payment_method_id: paymentMethodId,
          isUrgentHearing,
        }),
      })

      const res = await response.json()
      if (response.status !== 200 || res.error) {
        toast.error(res.error?.message || 'Error processing payment', {
          theme: 'colored',
        })
        return closeCheckoutModal()
      }
      res.is_urgent_hearing = isUrgentHearing
      createExpedient(res.paymentIntent)
    } catch (error) {
      toast.error('Algo salió mal', { theme: 'colored' })
    }
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()

    if (!stripe || !elements) {
      return
    }

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        checkoutIntent.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              email: activeUser.email,
              name: activeUser.name,
            },
          },
          setup_future_usage: 'off_session',
        }
      )

      if (error) {
        toast.error(error.message, { theme: 'colored' })
        return closeCheckoutModal()
      }

      createExpedient(paymentIntent)
    } catch (err) {
      toast.error('Se ha producido un error', { theme: 'colored' })
      closeCheckoutModal()
    }
  }

  const vat =
    activeUser.role !== 'admin'
      ? isUrgentHearing
        ? (price + (activeUser.urgent_hearing_extra_price || 30)) * 0.21
        : price * 0.21
      : price * 0.21

  const basePrice = price
  const hearingExtraPrice = activeUser.urgent_hearing_extra_price || 30

  const total =
    activeUser.role !== 'admin'
      ? isUrgentHearing
        ? price + hearingExtraPrice + vat
        : price + vat
      : price + vat

  return (
    <Modal isOpen={show} className="checkout-modal">
      <div className="d-flex justify-content-end">
        <button
          className="m-1 p-0 btn btn-danger d-flex justify-content-center align-items-center"
          style={{ width: '15px', height: '15px' }}
          onClick={closeCheckoutModal}
        >
          <IoMdClose />
        </button>
      </div>

      <ModalHeader>
        <div className="h3 mb-0">Pago</div>
      </ModalHeader>

      <ModalBody>
        <h4 className="blue2">Resumen</h4>

        <div className="card">
          <div className="card-body">
            <dl className="d-flex">
              <dt>Precio:</dt>
              <dd className="ms-auto">{basePrice}€</dd>
            </dl>
            {isUrgentHearing && hearingExtraPrice > 0 && (
              <dl className="d-flex">
                <dt>Extra Encargo Urgente:</dt>
                <dd className="ms-auto">{hearingExtraPrice}€</dd>
              </dl>
            )}
            <dl className="d-flex">
              <dt>IVA (21%):</dt>
              <dd className="ms-auto">{vat}€</dd>
            </dl>
            <hr />
            <dl className="d-flex">
              <dt>Total:</dt>
              <dd className="ms-auto text-dark b">
                <strong>{total}€</strong>
              </dd>
            </dl>
          </div>
        </div>
        <div style={{ display: `${!cards.length ? 'none' : ''}` }}>
          <h4 className="blue2 mb-0">Pague con una de sus tarjetas</h4>
          <ul className="col-md-8 col-12">
            {cards.map((card) => (
              <li
                key={card.pmId}
                className="my-3 d-flex flex-column"
                style={{ listStyle: 'none' }}
              >
                <Button
                  className="btn btn-success d-flex justify-content-between paySaveCardStripe"
                  onClick={() => paymentSaveCard(card.pmId)}
                >
                  <Icon.CreditCard />
                  <div>{card.brand}</div>
                  {` termina en ${card.last4}`}
                </Button>
              </li>
            ))}
          </ul>

          <Button
            style={{ display: `${showSaveCards ? '' : 'none'}` }}
            onClick={() => setShowSaveCards(false)}
            size="lg"
          >
            Añadir tarjeta
          </Button>
          <Button
            style={{ display: `${showSaveCards ? '' : 'none'}` }}
            onClick={closeCheckoutModal}
            size="lg"
            className=" text-center btn btn-danger m-1 sm-m-t-1"
          >
            Salir
          </Button>
        </div>

        <form
          style={{
            display: `${showSaveCards && !!cards.length ? 'none' : ''}`,
          }}
          onSubmit={handleSubmit}
        >
          <h4 className="blue2 mb-0">Tarjeta</h4>
          <small className="mb-3">
            (Número de tarjeta, fecha de caducidad, CVC, código postal)
          </small>

          <div className="mt-3">
            <CardElement className="MyCardElement" style={style} />
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-md-center m-3">
            <button
              id="oldFlowPayStripe"
              className="text-center btn btn-success m-1 sm-m-t-1 btn-lg"
            >
              Pagar de forma segura
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default CheckoutModal

const style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
}
