import React, { useState, useMemo, useEffect } from 'react'
import { promotionValidator } from '../redux/actions/promotion'
import Loading from './common/Loading'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { expedientStatusBadge, roundToTwoDecimals } from '../services/index'
import { useDispatch, useSelector } from 'react-redux'

const PaymentSummary = (props) => {
  const dispatch = useDispatch()
  const userInvoiceAccount = useSelector((state) => state.userInvoiceAccount)
  const promotionData = useSelector((state) => state.promotionData)
  const {
    basePrice = 0,
    promotion,
    setPromotion,
    defaultPromoState,
    expedient,
    goBack,
  } = props

  const [loading, setLoading] = useState(false)
  const [showPromoDiscount, setShowPromoDiscount] = useState(false)

  const currentAccount = useMemo(() => {
    return userInvoiceAccount.userInvoiceAccounts.find(
      (account) => account._id === expedient.user_invoice_account_id
    )
  }, [expedient, userInvoiceAccount])

  const handleChangePromotion = (event) => {
    setPromotion({
      ...promotion,
      showSubmitPromotionButton: !!event.target.value,
      name: event.target.value,
    })
  }

  const validatePromotion = () => {
    setLoading(true)
    dispatch(promotionValidator({ name: promotion.name }))
  }

  useEffect(() => {
    if (loading && promotionData) {
      // Si hay un error en la promoción
      if (promotionData.err) {
        setPromotion({
          ...defaultPromoState,
          name: promotion.name,
          promotionValidation: false,
        })
        setLoading(false)
      } else if (promotionData.promotionValidation !== null) {
        // Si la promoción es válida
        let {
          name,
          discount: { percentatge, value },
        } = promotionData

        // Ajustar los descuentos según las reglas de negocio
        if (
          percentatge &&
          !value &&
          basePrice - (basePrice * percentatge) / 100 < basePrice * 0.1
        ) {
          percentatge = 90
        }

        if (value && !percentatge && basePrice - value < 5) {
          value = basePrice - 5
        }

        setPromotion({
          ...promotion,
          promotionValidation: true,
          percentatge: percentatge,
          value: value,
          active: true,
          name,
        })
        setLoading(false)
      }
    }
  }, [
    promotionData,
    loading,
    promotion,
    setPromotion,
    defaultPromoState,
    basePrice,
  ])

  const calculateFinalPrice = (basePrice, promotion) => {
    if (promotion) {
      if (promotion.value) {
        return roundToTwoDecimals(basePrice - promotion.value)
      }
      if (promotion.percentatge) {
        return roundToTwoDecimals(
          basePrice - basePrice * (promotion.percentatge / 100)
        )
      }
    }
    return basePrice
  }

  const displayTaxLabel = (account) => {
    return !account || account?.tax_zone === 'spain' ? 'IVA (21%):' : 'Sin IVA:'
  }

  const applyTax = (price, taxZone = 'spain') => {
    if (taxZone === 'spain') {
      return roundToTwoDecimals(price * 1.21)
    }
    return roundToTwoDecimals(price)
  }

  const displayAppliedTax = (basePrice, promotion, account) => {
    const finalPrice = calculateFinalPrice(basePrice, promotion)
    return roundToTwoDecimals(
      Math.abs(finalPrice - applyTax(finalPrice, account?.tax_zone))
    )
  }

  const displayTotal = (basePrice, promotion, account) => {
    let priceAfterPromotion = basePrice

    if (promotion?.value) {
      priceAfterPromotion -= promotion.value
    } else if (promotion?.percentatge) {
      priceAfterPromotion -= basePrice * (promotion.percentatge / 100)
    }

    return priceAfterPromotion > 0
      ? roundToTwoDecimals(applyTax(priceAfterPromotion, account?.tax_zone))
      : 0
  }

  const getDiscountedPrice = (basePrice, promotion) => {
    if (promotion.value) {
      return roundToTwoDecimals(basePrice - promotion.value)
    } else if (promotion.percentatge) {
      return roundToTwoDecimals(
        basePrice - basePrice * (promotion.percentatge / 100)
      )
    }
    return basePrice
  }

  const displayDiscountedPrice = (basePrice, promotion) => {
    const discountedPrice = getDiscountedPrice(basePrice, promotion)
    return discountedPrice > 0 ? roundToTwoDecimals(discountedPrice) : 0
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="d-flex justify-content-between">
        <div
          id="createHearingAdminCompanyPartner"
          disabled={loading}
          onClick={goBack}
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon className="ms-3" size="2x" icon={faArrowLeft} />
        </div>
        <h4 className="blue2">Resumen del pago</h4>
        <div></div>
      </div>
      <div className="card">
        <div className="card-body">
          <dl className="d-flex">
            {expedient.is_urgent_hearing ? (
              <dt>Precio:</dt>
            ) : (
              <dt>
                Precio{' '}
                <span
                  onClick={() => setShowPromoDiscount(true)}
                  className="small text-secondary pointer"
                >
                  (tengo código de descuento)
                </span>
                :
              </dt>
            )}
            <dd className="ms-auto">
              {basePrice - (expedient.extra_urgent_price || 0)} €
            </dd>
          </dl>

          {expedient.extra_urgent_price > 0 && (
            <dl className="d-flex">
              <dt>Extra Encargo Urgente:</dt>
              <dd className="ms-auto">{expedient.extra_urgent_price} €</dd>
            </dl>
          )}

          {showPromoDiscount && (
            <div className="row">
              <div className="col-5">
                <div className="form-group d-flex">
                  <input
                    onChange={handleChangePromotion}
                    value={promotion.name}
                    name="promotion"
                    id="promotion"
                    type="text"
                    placeholder="Introduzca código promocional"
                    className="is-untouched is-pristine av-invalid form-control"
                  />
                </div>
              </div>

              {promotion.promotionValidation && (
                <div>{expedientStatusBadge('valido')}</div>
              )}
              {promotion.promotionValidation !== null &&
                !promotion.promotionValidation && (
                  <div>{expedientStatusBadge('invalido')}</div>
                )}
              {promotion.showSubmitPromotionButton && (
                <div className="ms-auto flex-column">
                  <Button
                    size="md"
                    className="ms-auto text-center btn btn-success"
                    onClick={validatePromotion}
                  >
                    Validar
                  </Button>
                </div>
              )}
            </div>
          )}

          {promotion.promotionValidation && (
            <div>
              <dl className="d-flex">
                <dt>Descuento: </dt>
                <dd className="ms-auto">
                  {promotion.value
                    ? `${promotion.value}€`
                    : `${promotion.percentatge}%`}
                </dd>
              </dl>

              <dl className="d-flex">
                <dt>Precio aplicado: </dt>
                <dd className="ms-auto">
                  {displayDiscountedPrice(basePrice, promotion)}€
                </dd>
              </dl>
            </div>
          )}

          <dl className="d-flex">
            <dt>{displayTaxLabel(currentAccount)} </dt>
            <dd className="ms-auto">
              <strong>
                {displayAppliedTax(basePrice, promotion, currentAccount)}€
              </strong>
            </dd>
          </dl>

          <hr />

          <dl className="d-flex">
            <dt>Total: </dt>
            <dd className="ms-auto text-dark b">
              <strong>
                {displayTotal(basePrice, promotion, currentAccount)}€{' '}
              </strong>
            </dd>
          </dl>
        </div>
      </div>
    </>
  )
}

export default PaymentSummary
