import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'

export function getPromotions({ query }) {
  const token = localStorage.getItem('token')

  const urlSearchParams = new URLSearchParams()

  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      urlSearchParams.append(key, query[key])
    }
  }

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/promotions?${urlSearchParams.toString()}`,
      method: 'GET',
      types: [
        types.GET_PROMOTIONS_REQUEST,
        types.GET_PROMOTIONS_SUCCESS,
        types.GET_PROMOTIONS_ERROR,
      ],
    },
  }
}

export function getPromotion({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/promotions/${id}`,
      method: 'GET',
      types: [
        types.GET_PROMOTION_REQUEST,
        types.GET_PROMOTION_SUCCESS,
        types.GET_PROMOTION_ERROR,
      ],
    },
  }
}

export function createPromotion({ promotion }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(promotion),
      endpoint: `${baseAPI}/promotions`,
      method: 'POST',
      types: [
        types.CREATE_PROMOTION_REQUEST,
        types.CREATE_PROMOTION_SUCCESS,
        types.CREATE_PROMOTION_ERROR,
      ],
    },
  }
}

export function updatePromotion({ promotion, id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(promotion),
      endpoint: `${baseAPI}/promotions/${id}`,
      method: 'PUT',
      types: [
        types.UPDATE_PROMOTION_REQUEST,
        types.UPDATE_PROMOTION_SUCCESS,
        types.UPDATE_PROMOTION_ERROR,
      ],
    },
  }
}

export function promotionValidator({ name }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/promotions/validate-promotions/${name}`,
      method: 'GET',
      types: [
        types.VALIDATE_PROMOTION_REQUEST,
        types.VALIDATE_PROMOTION_SUCCESS,
        types.VALIDATE_PROMOTION_ERROR,
      ],
    },
  }
}
