import React, { useState, useEffect } from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  InputGroup,
  Label,
} from 'reactstrap'
import { toast } from 'react-toastify'
import Can from '../common/Can'
import moment from 'moment'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeEmailRequest, changeEmail } from '../../redux/actions/auth'
import { PasswordEye } from '../PasswordEye'
import IBAN from 'iban'
import AcceptConditions from './AcceptConditions'

const ClientForm = (props) => {
  // const [utmSource, setUtmSource] = useState(null)
  // const [utmCampaign, setUtmCampaign] = useState(null)
  // const [utmMedium, setUtmMedium] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [allowChangeEmail, setAllowChangeEmail] = useState(
    props.user?.allow_change_email || false
  )

  const location = useLocation()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const { activeUser } = auth

  useEffect(() => {
    // const params = new URLSearchParams(location.search)
    // const utmSource = params.get('utm_source') || ''
    // const utmCampaign = params.get('utm_campaign') || ''
    // const utmMedium = params.get('utm_medium') || ''

    // if (utmSource || utmCampaign) {
    //   setUtmSource(utmSource)
    //   setUtmCampaign(utmCampaign)
    //   setUtmMedium(utmMedium)
    // }

    // if (props.searchParams) {
    //   setUtmSource(props.searchParams.utm_source || '')
    //   setUtmCampaign(props.searchParams.utm_campaign || '')
    //   setUtmMedium(props.searchParams.utm_medium || '')
    // }

    if (
      activeUser.role !== 'admin' &&
      props.formVisibility?.formInfo &&
      !activeUser.active_campaign_hearing_quantity_prevision &&
      !props.isNew
    ) {
      toast.error(
        'Por favor, complete la información de perfil para poder categorizarlo adecuadamente',
        { theme: 'colored' }
      )
    }
  }, [
    location,
    props.searchParams,
    activeUser,
    props.formVisibility,
    props.isNew,
  ])

  const handleOnHover = () => {
    setShowPassword(!showPassword)
  }

  const handleChangeNewEmail = (e) => {
    setNewEmail(e.target.value)
  }

  const handleChangeVerificationCode = (e) => {
    setVerificationCode(e.target.value)
  }

  const handleOnClickChangeEmail = () => {
    if (!verificationCode || !newEmail) {
      toast.error('Falta el nuevo email o el código de verificación', {
        theme: 'colored',
      })
      return
    }

    dispatch(
      changeEmail({
        verificationCode,
        email: newEmail,
      })
    )
    setNewEmail('')
    setVerificationCode('')
    setAllowChangeEmail(false)
  }

  const submit = (e, errors, values) => {
    if (!IBAN.isValid(values.bank_iban) && values.bank_iban)
      return toast.error('El Iban es incorrecto')

    if (errors.length > 0 || values.password === '') {
      return toast.error('Por favor rellene los campos correctamente', {
        theme: 'colored',
      })
    }

    const { user, onSubmit } = props

    if (
      !props.isNew &&
      (!user?.active_campaign_hearing_quantity_prevision ||
        values?.active_campaign_hearing_quantity_prevision === 'Seleccionar...')
    ) {
      toast.error(
        'Por favor, complete la información de perfil para poder categorizarlo adecuadamente',
        { theme: 'colored' }
      )
    }

    if (user && user._id) {
      values.is_substitute = values.is_substitute === 'Si'
      values.is_substitute_accepted = values.is_substitute_accepted === 'Si'
      values.is_procurador = values.is_procurador === 'Si'
      values.is_procurador_accepted = values.is_procurador_accepted === 'Si'
    }

    values.allow_change_email = values.allow_change_email === 'Sí'
    values.block_expedient_creation = values.block_expedient_creation === 'Sí'
    values.block_sepa_stripe = values.block_sepa_stripe === 'Sí'
    values.block_telematic_expedients =
      values.block_telematic_expedients === 'Sí'

    values.active_campaign_legal_area =
      values.active_campaign_legal_area === 'Sí'

    if (
      !user?.active_campaign_hearing_quantity_prevision &&
      values?.active_campaign_hearing_quantity_prevision === 'gt50'
    ) {
      toast.success(
        'Gracias por completar la información de tu perfil. Debido al volumen de contratación, si no lo hemos hecho anteriormente nos pondremos en contacto contigo.',
        { theme: 'colored' }
      )
    }

    return onSubmit(values)
  }

  // La función render del componente original se convierte en la parte del JSX de retorno
  const {
    user = {},
    // showPassword: showPassProp = true,
    isNew = false,
    formVisibility = {},
  } = props
  const { formGeneral = false, formInfo = true } = formVisibility
  const submitText = user._id ? 'Guardar' : 'Crear usuario'

  return (
    <AvForm onSubmit={submit}>
      {formGeneral && (
        <Can I="update role" of="User">
          {user._id && (
            <Card>
              <CardBody>
                <h3 className="mt-0 mb-4 d-flex align-items-center border-bottom pb-2 text-primary">General</h3>
                <Row>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="block_expedient_creation"
                      label="Bloqueo creación expedientes"
                      value={user.block_expedient_creation ? 'Sí' : 'No'}
                      type="select"
                    >
                      <option>No</option>
                      <option>Sí</option>
                    </AvField>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="block_sepa_stripe"
                      label="Bloqueo pago por Stripe - SEPA"
                      value={user.block_sepa_stripe ? 'Sí' : 'No'}
                      type="select"
                    >
                      <option>No</option>
                      <option>Sí</option>
                    </AvField>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="block_telematic_expedients"
                      required
                      label="Bloqueo creación expedientes telemáticos"
                      errorMessage="seleccione"
                      value={
                        user.block_telematic_expedients ||
                        user.block_expedient_creation
                          ? 'Sí'
                          : 'No'
                      }
                      type="select"
                      disabled={user.block_expedient_creation}
                    >
                      <option>Sí</option>
                      <option>No</option>
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="role"
                      required
                      label="Rol"
                      errorMessage="seleccione el rol"
                      value={user.role}
                      type="select"
                    >
                      <option disabled>admin</option>
                      <option>client</option>
                      <option>company</option>
                      <option>partner</option>
                    </AvField>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="is_substitute"
                      required
                      label="Sustituto"
                      value={user.is_substitute ? 'Si' : 'No'}
                      type="select"
                    >
                      <option>Si</option>
                      <option>No</option>
                    </AvField>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="is_procurador"
                      required
                      label="Procurador"
                      value={user.is_procurador ? 'Si' : 'No'}
                      type="select"
                    >
                      <option>Si</option>
                      <option>No</option>
                    </AvField>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <Label>Orígen Comercial</Label>
                    <div className="d-flex">
                      <div className="p-0 flex-fill">
                        <AvField
                          name="utm_source"
                          value={user.utm_source || user.source}
                          type="text"
                          defaultValue="organic"
                        />
                      </div>
                      <div className="d-flex align-items-end pointer">
                        <a
                          type="button"
                          className="btn mb-3 border d-flex align-items-center justify-content-center"
                          style={{
                            height: '31px',
                            width: '31px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px',
                          }}
                          href="https://docs.google.com/spreadsheets/d/1rs3opbBBv6p8H8N4lo5sMRhcuibMoOatElNnaueiyU4/edit#gid=1165658892"
                          target="blank"
                        >
                          ?
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <Label>Campaña Márqueting</Label>
                    <div className="d-flex">
                      <div className="p-0 flex-fill">
                        <AvField
                          name="utm_campaign"
                          value={user.utm_campaign}
                          type="text"
                          defaultValue=""
                        />
                      </div>
                      <div className="d-flex align-items-end pointer">
                        <a
                          type="button"
                          className="btn mb-3 border d-flex align-items-center justify-content-center"
                          style={{
                            height: '31px',
                            width: '31px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px',
                          }}
                          href="https://docs.google.com/spreadsheets/d/1rs3opbBBv6p8H8N4lo5sMRhcuibMoOatElNnaueiyU4/edit#gid=459769384"
                          target="blank"
                        >
                          ?
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-0 mb-4 d-flex align-items-center border-bottom pb-2 text-primary">Encargos</h3>
                <Row>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="default_expedient_price"
                      label="Importe encargo por defecto (€)"
                      value={user.default_expedient_price}
                      type="number"
                      defaultValue={95}
                    />
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="default_notary_price"
                      label="Importe notaria por defecto (€)"
                      value={user.default_notary_price || 95}
                      type="number"
                    />
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="default_telematic_price"
                      label="Importe vista telematica por defecto (€)"
                      value={user.default_telematic_price || '0'}
                      type="number"
                    />
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="default_procura_price"
                      label="Importe procurador por defecto (€)"
                      value={user.default_procura_price || 45}
                      type="number"
                    />
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="default_procura_telematic_price"
                      label="Importe procura telematica por defecto (€)"
                      value={user.default_procura_telematic_price || 45}
                      type="number"
                    />
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="urgent_hearing_extra_price"
                      label="Importe extra encargo urgente (€)"
                      value={user.urgent_hearing_extra_price}
                      type="number"
                      defaultValue={30}
                    />
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      type="number"
                      name="default_client_invoice_period"
                      label="Periodo de facturación (días)"
                      value={user.default_client_invoice_period}
                      defaultValue={30}
                    />
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      type="number"
                      name="cancelation_price"
                      label="Importe cancelación express (€)"
                      value={user.cancelation_price}
                      defaultValue={50}
                    />
                  </Col>
                </Row>
                <h4 className="mb-3 blue2">Usuario</h4>
                <Row>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="allow_change_email"
                      required
                      label="Cambiar Email"
                      errorMessage="seleccione"
                      value={user.allow_change_email ? 'Sí' : 'No'}
                      type="select"
                    >
                      <option>Sí</option>
                      <option>No</option>
                    </AvField>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <AvField
                      name="user_create_at"
                      label="Fecha de creación de usuario"
                      value={moment(user.created_at).format('DD-MM-YYYY hh:mm')}
                      type="text"
                      disabled
                    />
                  </Col>
                </Row>

                <Button
                  color="primary"
                  size="lg"
                  className="mt-3 me-4 mb-3"
                  key="sub"
                >
                  {submitText}
                </Button>
              </CardBody>
            </Card>
          )}
        </Can>
      )}

      {formInfo && (activeUser?.role === 'admin' || !isNew || !user.phone) && (
        <Card className="mb-0 bg-white">
          <CardBody className="mb-0">
            {isNew && (
              <h3 className="mt-0 mb-4 d-flex align-items-center border-bottom pb-2 text-primary">Información de contacto</h3>
            )}
            <Row>
              <Col md="6">
                <AvField
                  name="name"
                  required
                  label="Nombre"
                  value={user.name}
                  errorMessage="Introduce tu nombre"
                />
              </Col>
              <Col md="6">
                <AvField
                  name="last_name"
                  required
                  label="Apellidos"
                  value={user.last_name}
                  errorMessage="Introduce los apellidos"
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <AvField
                  name="email"
                  required
                  label="Email"
                  type="email"
                  value={user.email}
                  errorMessage="Introduce el email"
                  disabled={!!user.email}
                />
              </Col>
              <Col md="6">
                <AvField
                  name="phone"
                  required
                  label="Teléfono"
                  value={user.phone}
                  validate={{
                    pattern: {
                      value:
                        '/^(\\+34|0034|34)?((8|9)[0-9]\\d{7}|(6|7)\\d{8})$/gm',
                    },
                  }}
                  errorMessage="Introduce el teléfono, sólo números, sin espacios ni guiones"
                />
              </Col>
            </Row>
            {!isNew && user.active_campaign_hearing_quantity_prevision && (
              <Button
                color="primary"
                size="lg"
                className="mt-3 me-4 mb-3"
                key="sub"
              >
                {submitText}
              </Button>
            )}

            {showPassword && (
              <>
                {!isNew && <h3 className="mt-0 mb-4 d-flex align-items-center border-bottom pb-2 text-primary">Seguridad</h3>}
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="12">
                        <Label for="password"> Crea una contraseña </Label>
                      </Col>
                    </Row>
                    <InputGroup className="text-bottom">
                      <Row>
                        <Col md="8" className="me-0 pe-0">
                          <AvField
                            name="password"
                            required
                            bsSize="lg"
                            type={showPassword ? 'text' : 'password'}
                            validate={{
                              pattern: {
                                value:
                                  '/^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9])\\S{8,}$/g',
                              },
                              minLength: { value: 8 },
                            }}
                            errorMessage="Mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial"
                            style={{
                              borderBottomRightRadius: '0px',
                              borderTopRightRadius: '0px',
                            }}
                          />
                        </Col>
                        <PasswordEye
                          showPassword={showPassword}
                          handleOnHover={handleOnHover}
                        />
                      </Row>
                    </InputGroup>
                  </Col>
                </Row>
                <AcceptConditions />
                <Button color="primary" size="lg" className="me-4" key="sub">
                  {submitText}
                </Button>
                {!user._id && (
                  <p className="mt-3 mb-0">
                    {' '}
                    ¿Ya estás registrado en Legal Pigeon?{' '}
                    <Link
                      style={{ color: 'unset', textDecoration: 'unset' }}
                      to="/login"
                    >
                      Accede
                    </Link>
                  </p>
                )}
              </>
            )}
          </CardBody>
        </Card>
      )}

      {formInfo &&
        (activeUser?.role === 'admin' ||
          (!isNew && !user.active_campaign_hearing_quantity_prevision)) && (
          <Card className="mb-0 bg-white">
            <CardBody className="mb-0">
              {isNew && (
                <h3 className="mt-0 mb-4 d-flex align-items-center border-bottom pb-2 text-primary">
                  {activeUser?.role === 'admin'
                    ? 'Información adicional'
                    : 'Queremos conocerte un poco más...'}
                </h3>
              )}
              <Row>
                <Col md="6">
                  <AvField
                    name="active_campaign_legal_area"
                    label="¿Perteneces a un área legal de una empresa?"
                    value={
                      user.hasOwnProperty('active_campaign_legal_area')
                        ? !!user.active_campaign_legal_area
                          ? 'Sí'
                          : 'No'
                        : null
                    }
                    type="select"
                    errorMessage="Selecciona una opción"
                  >
                    <option>Seleccionar...</option>
                    <option value="No">No</option>
                    <option value="Sí">Sí</option>
                  </AvField>
                </Col>
                <Col md="6">
                  <AvField
                    name="active_campaign_hearing_quantity_prevision"
                    required
                    label="Volumen de contratación de encargos aproximada (mensual)"
                    value={
                      user.active_campaign_hearing_quantity_prevision || null
                    }
                    type="select"
                    errorMessage="Selecciona una opción"
                  >
                    <option>Seleccionar...</option>
                    <option value="lt10">menos de 10 encargos</option>
                    <option value="btw10-50">entre 10 y 50 encargos</option>
                    <option value="gt50">más de 50 encargos</option>
                  </AvField>
                </Col>
              </Row>
              <Button
                color="primary"
                size="lg"
                className="mt-3 me-4 mb-3"
                key="sub"
              >
                {submitText}
              </Button>
            </CardBody>
          </Card>
        )}

      {formInfo && allowChangeEmail && activeUser.role !== 'admin' && (
        <Card>
          <CardHeader>
            <h3 className="mt-0 mb-4 d-flex align-items-center border-bottom pb-2 text-primary">Cambio de email</h3>
            <ol>
              <li> Introduce el nuevo email y clica solicitar código.</li>
              <li> Recibirás el código en el email indicado.</li>
              <li>
                {' '}
                Introduce el código para confirmar el cambio de email y clica
                cambiar email.{' '}
              </li>
              <li>
                {' '}
                Si no hay ningún problema la sesión se cerrará y deberás acceder
                con el nuevo email.{' '}
              </li>
            </ol>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="6">
                <AvField
                  name="newEmail"
                  label="Nuevo email"
                  value={newEmail}
                  onChange={handleChangeNewEmail}
                />
                <Button
                  type="button"
                  color="primary"
                  size="lg"
                  onClick={() =>
                    dispatch(changeEmailRequest({ email: newEmail }))
                  }
                >
                  Solicitar código
                </Button>
              </Col>
              <Col sm="6">
                <AvField
                  name="verificationCode"
                  label="Código de verificación"
                  value={verificationCode}
                  onChange={handleChangeVerificationCode}
                />
                <Button
                  type="button"
                  color="primary"
                  size="lg"
                  onClick={handleOnClickChangeEmail}
                >
                  Cambiar Email
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </AvForm>
  )
}

export default ClientForm
