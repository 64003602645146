import React from 'react'
import logoImg from '../../assets/images/2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPersonWalking,
  faGavel,
  faHandshakeSimple,
  faFileSignature,
} from '@fortawesome/free-solid-svg-icons'
import { ENABLE_PIGEON_IN_HOUSE } from '../../config'
import { Card, CardBody } from 'reactstrap'
import { useNavigate } from 'react-router'

const options = [
  {
    title: 'Pigeon substitute',
    content:
      'Contrata un letrado para representarte en cualquier parte de España, en actuaciones judiciales y extrajudiciales, o para cualquier otra actuación que necesites!',
    fontAwesome: faGavel,
    to: '/panel/hearing',
    show: true,
    isNew: false,
    isCommingSoon: false,
  },
  {
    title: 'Pigeon procura',
    content:
      'Contamos con procuradores listos para ofrecerte una sustitución eficiente y profesional, asegurando que cada actuación judicial sea manejada con la máxima diligencia y expertise.',
    fontAwesome: faFileSignature,
    to: '/panel/new-procura',
    show: true,
    isNew: new Date() < new Date('2024-12-31'),
    isCommingSoon: false,
    disabled: false,
  },
  {
    title: 'Pigeon on call',
    content:
      'Te ayudamos a confeccionar y revisar escritos judiciales. Nuestra red de abogados especializados está disponible para apoyar a tu organización en la redacción y revisión de documentos legales con la máxima precisión y profesionalismo.',
    fontAwesome: faHandshakeSimple,
    to: '/panel/project-short-term',
    show: true,
    isNew: false,
    isCommingSoon: false,
  },
  // {
  //   title: 'Pigeon in House',
  //   subtitle:
  //     '¿Necesitas un abogado dedicado exclusivamente a ti por más de 15 días? ',
  //   content:
  //     'Asegura la disponibilidad constante de un abogado adaptado a tus requerimientos para respaldarte a lo largo del tiempo que necesites.',
  //   fontAwesome: faPersonWalking,
  //   to: '/panel/new-pigeon-inhouse',
  //   show: false,
  //   isNew: false,
  //   isCommingSoon: true,
  //   disabled: true,
  // },
]

const CreateNewAssigment = ({ closeModal }) => {
  const navigate = useNavigate()

  return (
    <div className="d-flex justify-content-center align-items-start mx-4">
      <div
        className="d-flex flex-column align-items-baseline"
        style={{ width: '500px' }}
      >
        {options.map((option) => {
          const badgeStyle = {
            position: 'absolute',
            top: '10px',
            right: '10px',
            transform: 'rotate(45deg)',
            backgroundColor: 'white',
            border: `2px solid ${option.isNew ? 'green' : 'black'}`,
            borderRadius: '10px',
            padding: '3px',
            color: option.isNew ? 'green' : 'black',
            fontWeight: 'bold',
          }

          return (
            <Card
              className={`mb-3 card-link pointer w-100  ${
                !option.show || option.disabled ? 'disabled' : ''
              }`}
              key={option.title}
              onClick={() => {
                if (option.show && !option.disabled) {
                  navigate(option.to)
                  closeModal()
                }
              }}
              style={{
                cursor:
                  !option.show || option.disabled ? 'not-allowed' : 'pointer',
              }}
            >
              <CardBody className="d-flex align-items-center  border border-primary rounded">
                <div style={{ width: '35px', marginRight: '10px' }}>
                  <FontAwesomeIcon
                    className="me-3"
                    size="2x"
                    icon={option.fontAwesome}
                  />
                </div>
                <div>
                  {(option.isNew || option.isCommingSoon) && (
                    <h4 style={badgeStyle}>
                      {option.isNew ? 'New!!' : 'Coming soon!'}
                    </h4>
                  )}
                  <h3 style={{ textAlign: 'left', color: 'inherit' }}>
                    <strong>{option.title}</strong>
                  </h3>
                  {option.subtitle && (
                    <p className="mb-1 strong" style={{ textAlign: 'left' }}>
                      <strong>{option.subtitle}</strong>
                    </p>
                  )}
                  <p className="mb-0" style={{ textAlign: 'left' }}>
                    {option.content}
                  </p>
                </div>
              </CardBody>
            </Card>
          )
        })}
      </div>
      {window.innerWidth > 716 && (
        <div
          className="d-flex justify-content-center mx-3"
          style={{ maxWidth: '40%' }}
        >
          <img src={logoImg} alt="logo" className="img-fluid" />
        </div>
      )}
    </div>
  )
}

export default CreateNewAssigment
